<div class="flex">
  <div class="left">
    <div class="images-block">
      <img class="logo" src="{{cdn_url}}/assets/icons/{{logo_url}}" alt="">
      <img class="main-picture" src="{{cdn_url}}/assets/images/login-main-picture.png" alt="">
    </div>
    
    <h3>Le <b>Système Permanent d’Observation Territoriale (SPOT) </b> du marché de la construction, est le principal outil de veille et de prospective économique rattaché au secteur. Intégralement conçu et développé par la CERC Guyane, avec le concours de l’UE, le SPOT est un système d’information collaboratif intégré qui permet d’appréhender au plus près les dynamiques de marchés, leur temporalité et leur territorialisation</h3>
    <span>@ Plateforme collaborative réalisée par Yukan Innovate</span>
  </div>
  <div class="right">
    <form>
      <h2>{{ 'login.login_title' | translate }}</h2>
      <div class="input">
        <span>{{ 'login.email' | translate }}*</span>
        <div>
          <img width="20px" src="{{cdn_url}}/assets/icons/login-email.svg" alt="">
          <input matInput type="email" name="email" [(ngModel)]="user.email"
                 placeholder="{{ 'login.email-help' | translate }}">
        </div>
      </div>
      <div class="input">
        <span>{{ 'login.password' | translate }}*</span>
        <div>
          <img width="15px" src="{{cdn_url}}/assets/icons/login-password.svg" alt="">
          <input matInput type="password" name="password" [(ngModel)]="user.password"
                 placeholder="{{ 'login.password-help' | translate }}">
        </div>
      </div>
      <div class="forgot-password" (click)="redirect('/forgot-password')">
      <span>
        {{ 'login.forgot_password' | translate }}
      </span>
      </div>
      <div class="buttons">
        <button class="signup" type="button"
                (click)="redirect('/registration')">{{ 'login.signup' | translate }}</button>
        <button class="login" type="submit" (click)="login()">{{ 'login.login_button' | translate }}</button>
      </div>
    </form>
  </div>
</div>
