import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/shared/services/auth.service';
import {CardModel} from '../../model/card-model';
import {OperationService} from '../../service/operation.service';
import * as constant from '../../../app.constant';
import {AdvancedSearchService} from '../../service/advanced-search-service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AppStateService} from "../../../shared/services/app-state.service";

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit, OnDestroy {
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  cards: CardModel[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(private operationService: OperationService, private advancedSearchService: AdvancedSearchService,
              private router: Router, private authService: AuthService, private activatedRoute: ActivatedRoute,
              private translate: TranslateService, private appState: AppStateService) {
  }

  ngOnInit(): void {
    this.getQueryparams();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getCardsData(): void {
    this.operationService.getCardsData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.operationService.getCardsAmountData()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(value => {
            this.cards = this.mapCardData(resp, value);
          });
      });
  }

  private getQueryparams(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.advancedSearchService.setup();
        if (params?.beforeLoad == 'true') {
          this.advancedSearchService.setOperationType$.next('skip');
        } else if (params?.type) {
          this.advancedSearchService.searchModel.type = params?.type;
          this.advancedSearchService.setOperationType$.next(params?.type);
        } else if (!params.beforeLoad) {
          this.advancedSearchService.setOperationType$.next(null);
        }
        this.getCardsData();
      });
  }

  public mapCardData(data, values): CardModel[] {
    let cards: CardModel[] = [];
    cards.push({
      title: this.translate.instant('cards.completed'),
      amount: values.archivee,
      count: data.finished,
      icon: "active-dashboard",
      url: "completed"
    });
    if (!this.authService.isUserEnterprise()) {
      cards.push({
        title: this.translate.instant('cards.waiting'),
        amount: values.avalider,
        count: data.pending,
        icon: "waiting-dashboard",
        url: "waiting"
      });
    }
    cards.push({
      title: this.translate.instant('cards.inprogress'),
      amount: values.vivante,
      count: data.inprogress,
      conflict: data.vivconflict,
      icon: "alive-dashboard",
      url: "inprogress"
    });
    cards.push({
      title: this.translate.instant('cards.planned'),
      amount: values.projetee,
      count: data.planned,
      conflict: data.projconflict,
      icon: "projected-dashboard",
      url: "planned"
    });
    return cards;
  }

  public clickCard(url: string): void {
    this.advancedSearchService.searchModel.operationType = url;
    this.appState.applyFilters$.emit();
    this.router.navigate(['/operations/' + url]);
  }
}
