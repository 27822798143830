import {Component, OnDestroy, OnInit} from '@angular/core';
import * as constant from '../app.constant';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {UserRegistrationModel} from "./models/user-registration-model";
import {RegistrationService} from "./services/registration.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs/internal/Subject";
import Swal from "sweetalert2";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public user: UserRegistrationModel = {};
  public form: FormGroup;
  private unsubscribe$ = new Subject<void>();
  private nonWhitespaceRegExp: RegExp = new RegExp('\\S');
  private emailRegex: RegExp = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
  public logo_url: string = constant.ACTIVE_PROJECT.sidebar_logo;

  constructor(private fb: FormBuilder, private router: Router, private translate: TranslateService,
              private registrationService: RegistrationService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
  }

  private initForm() {
    this.form = this.fb.group({
      firstname: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      lastname: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      email: [null, [Validators.required, Validators.pattern(this.emailRegex)]],
      phone: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      type_organisation: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      name_organisation: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      object_request: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      function: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      password: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      confirmPassword: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]]
    });
  }

  public redirect(url: string): void {
    this.router.navigateByUrl(url);
  }

  register() {
    if (!this.validate()) {
      return;
    }
    this.buildUserData();
    this.registrationService.register(this.user)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
          if (value.success == 'true') {
            this.redirect('/login');
          } else {
            Swal('Oops...', value.message, 'error');
          }
        }
      );
  }

  private validate() {
    // check password == confirmation
    if (this.form.controls['password'].value !== this.form.controls['confirmPassword'].value) {
      Swal('Oops...', 'Your password and confirmation password do not match', 'error');
      return false;
    }

    if (this.form.invalid) {
      Swal('Oops...', 'Please enter valid data', 'error');
      return false;
    }
    return true;
  }

  private buildUserData() {
    this.user.name = this.user.name.trim();
    this.user.surname = this.user.surname.trim();
    this.user.email = this.user.email.trim();
    this.user.password = this.user.password.trim();
    this.user.name_of_organisation = this.user.name_of_organisation.trim();
    this.user.type_of_organisation = this.user.type_of_organisation.trim();
    this.user.function = this.user.function.trim();
    this.user.object_request = this.user.object_request.trim();
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }
}
