import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {IConfig, NON_WHITESPACE_REGEX} from "./IConfig";
import {Observable} from "rxjs";
import {HttpClientService} from "../../shared/services/http-client.service";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {TaskStatusObjectModel} from "../models/task-status-object-model";

export class TaskStatusConfig implements IConfig {
  name = 'task-status';
  form = new FormGroup({});
  model = new TaskStatusObjectModel();
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  columns = ['id', 'name', 'color'];
  defaultHttpParams = { orderby: 'id', order: 'ASC' };

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.translate.get(['settings.task-status-name', 'settings.task-status-name-help', 'settings.task-status-color', 'settings.task-status-color-help']).subscribe(value => {
      this.fields = [
        {
          key: 'name',
          type: 'input',
          name: 'name',
          templateOptions: {
            label: value['settings.task-status-name'],
            placeholder: value['settings.task-status-name-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        },
        {
          key: 'color',
          type: 'input',
          name: 'color',
          templateOptions: {
            label: value['settings.task-status-color'],
            placeholder: value['settings.task-status-color-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        }
      ];
    });
  }

  getData(params = null): Observable<any> {
    return this.http.post('/task/getStatuses', params).pipe(map(value => value.data));
  }

  addItem(): Observable<boolean> {
    return this.http.post('/task/saveStatus', {'status': this.model}).pipe(map(value => value.success));
  }

  editItem(): Observable<boolean> {
    return this.http.post('/task/saveStatus', {'status': this.model}).pipe(map(value => value.success));
  }

  getItemData(id): Observable<any> {
    return this.http.post('/task/getStatus', {id: id}).pipe(map(value => value.data));
  }

  deleteItem(id): Observable<boolean> {
    return this.http.post('/task/deleteStatus', {id: id}).pipe(map(value => value.success));
  }

  setOrder(field) {
    // check if the field is ID
    if (field === 'id') {
      this.setOrderParameter(field);
    } else {
      // otherwise get name from the form fields
      const name = this.fields.find(value => value.name === field)?.key.toString();
      if (name) {
        this.setOrderParameter(name);
      } else {
        this.setOrderParameter(field);
      }
    }
  }

  private setOrderParameter(newParameter) {
    if (this.defaultHttpParams.orderby === newParameter) {
      this.setOrderType();
    } else {
      this.defaultHttpParams.orderby = newParameter;
    }
  }

  private setOrderType() {
    if (this.defaultHttpParams.order === 'desc') {
      this.defaultHttpParams.order = 'asc';
    } else {
      this.defaultHttpParams.order = 'desc';
    }
  }
}
