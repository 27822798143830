import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {SubsectorObjectModel} from "../models/subsector-object-model";
import {IConfig, NON_WHITESPACE_REGEX} from "./IConfig";
import {Observable} from "rxjs";
import {HttpClientService} from "../../shared/services/http-client.service";
import {map} from "rxjs/operators";
import {Sector} from "../../shared/enums/sector";
import {Subsector} from "../../shared/enums/subsector";
import {TranslateService} from "@ngx-translate/core";

export class SubSectorConfig implements IConfig {
  name = 'subsector';
  form = new FormGroup({});
  model = new SubsectorObjectModel();
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  columns = ['id', 'code', 'description', 'sector', 'sub_sector'];
  defaultHttpParams = {orderby: 'code', order: 'desc'};

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.translate.get(['settings.code', 'settings.code-help', 'settings.description', 'settings.description-help',
      'settings.sector', 'settings.sector-help', 'settings.sub_sector', 'settings.sub_sector-help']).subscribe(value => {
      this.fields = [
        {
          key: 'code',
          type: 'input',
          name: 'code',
          templateOptions: {
            label: value['settings.code'],
            placeholder: value['settings.code-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        },
        {
          key: 'sector',
          type: 'select',
          name: 'sector',
          templateOptions: {
            label: value['settings.sector'],
            placeholder: value['settings.sector-help'],
            required: true,
            options: Sector.mapToDropdown()
          }
        },
        {
          key: 'sub_sector',
          type: 'select',
          name: 'sub_sector',
          templateOptions: {
            label: value['settings.sub_sector'],
            placeholder: value['settings.sub_sector-help'],
            required: true,
            options: Subsector.mapToDropdown()
          }
        },
        {
          key: 'description',
          type: 'input',
          name: 'description',
          templateOptions: {
            label: value['settings.description'],
            placeholder: value['settings.description-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        }
      ];
    });
  }

  getData(params = null): Observable<any> {
    if (!params) {
      params = this.defaultHttpParams;
    }
    return this.http.post('/sector/sectorlist', params).pipe(map(value => value.data));
  }

  addItem(): Observable<boolean> {
    return this.http.post('/sector/addsector', this.model).pipe(map(value => value.success));
  }

  editItem(): Observable<boolean> {
    return this.http.post('/sector/editsector', this.model).pipe(map(value => value.success));
  }

  getItemData(id): Observable<any> {
    return this.http.post('/sector/getsector', {id: id}).pipe(map(value => value.data));
  }

  deleteItem(id): Observable<boolean> {
    return this.http.post('/sector/delete', {id: id}).pipe(map(value => value.success));
  }

  setOrder(field) {
    // check if the field is ID
    if (field === 'id') {
      this.setOrderParameter(field);
    } else {
      // otherwise get name from the form fields
      const name = this.fields.find(value => value.name === field)?.key.toString();
      if (name) {
        this.setOrderParameter(name);
      } else {
        this.setOrderParameter(field);
      }
    }
  }

  private setOrderParameter(newParameter) {
    if (this.defaultHttpParams.orderby === newParameter) {
      this.setOrderType();
    } else {
      this.defaultHttpParams.orderby = newParameter;
    }
  }

  private setOrderType() {
    if (this.defaultHttpParams.order === 'desc') {
      this.defaultHttpParams.order = 'asc';
    } else {
      this.defaultHttpParams.order = 'desc';
    }
  }
}
