import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {HousingModel} from '../../models/housing-model';
import {ApeCodeModel} from '../../../operation/model/ape-code-model';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {HousingService} from '../../services/housing-service';
import {OperationService} from '../../../operation/service/operation.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-housing',
  templateUrl: './edit-housing.component.html',
  styleUrls: ['./edit-housing.component.scss']
})
export class EditHousingComponent implements OnInit, OnDestroy {
  public house: HousingModel = new HousingModel();
  public codes: ApeCodeModel[];
  private unsubscribe$ = new Subject<void>();
  public index: number;

  constructor(public translate: TranslateService, private housingService: HousingService, private operationService: OperationService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.loadDataLists(data);
  }

  ngOnInit(): void {
  }

  private loadDataLists(data): void {
    this.codes = data.codes;
    this.house = {
      housingId: data.house.housingId,
      name: data.house.name,
      code_ape: data.house.code_ape,
      company_name: data.house.company_name,
      price: data.house.price,
      fk_codeAPE: data.house.fk_codeAPE,
    };
    this.index = data.index;
  }

  public cancel(): void {
    this.housingService.closeHousing.next();
  }

  public saveHouse(): void {
    this.housingService.editHousingList.next({house: this.house, index: this.index});
    this.cancel();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getApeCode(id) {
    let code = this.codes.find(c => c.id == id);
    return code.description + '-' + code.code;
  }

}
