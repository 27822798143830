import {Subject} from "rxjs";
import {FormControl} from "@angular/forms";
import * as constant from "../../app.constant";

export class ListComponentModel {
  public unsubscribe$ = new Subject<void>();
  public isRequestSend = false;
  public previousActiveColumn: HTMLElement;
  public isSearchVisible = false;
  public search: FormControl = new FormControl();
  public isLoaded = true;
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public api_url: string = constant.ACTIVE_ENVIRONMENT.api_url;
}
