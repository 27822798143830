import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivityType} from "../../../shared/enums/activity-type";
import {AppStateService} from "../../../shared/services/app-state.service";
import {AdvancedActivityService} from "../../services/advanced-activity.service";
import {format} from "date-fns";

@Component({
  selector: 'app-activity-search',
  templateUrl: './activity-search.component.html',
  styleUrls: ['./activity-search.component.scss']
})
export class ActivitySearchComponent implements OnInit {
  public search: any = {};
  public activityTypes = ActivityType;

  constructor(public dialog: MatDialog, private appState: AppStateService, private activitySearch: AdvancedActivityService) {
  }

  ngOnInit(): void {
    this.activitySearch.setup();
    this.search = this.activitySearch.searchModel.advancedsearch;
  }

  close() {
    this.dialog.closeAll();
  }

  filter() {
    // this.convertDate();
    this.appState.applyFilters$.emit();
    this.appState.applySearch$.emit();
    this.close();
  }

  convertDate() {
    this.search.date._d ?
      this.search.date = format(new Date(this.search.date._d), 'yyyy-MM-dd') :
      this.search.date = format(new Date(this.search.date), 'yyyy-MM-dd');
  }
}
