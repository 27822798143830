import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AdvancedSearchService} from 'src/app/operation/service/advanced-search-service';
import {AuthService} from 'src/app/shared/services/auth.service';
import * as constant from '../../app.constant';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {UpdateOperationListComponent} from '../../update-operations/components/update-operation-list/update-operation-list.component';
import {takeUntil} from 'rxjs/operators';
import {UpdateOperationsService} from '../../update-operations/services/update-operations.service';
import {Subject} from 'rxjs';
import {AppStateService} from '../../shared/services/app-state.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit, OnDestroy {
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public logo_url: string = constant.ACTIVE_PROJECT.sidebar_logo;
  public activeMenuItem = 'operations';
  public months = [];
  private unsubscribe$ = new Subject<void>();


  constructor(
    public authService: AuthService,
    private router: Router,
    private advancedSearchService: AdvancedSearchService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private updateOperationsService: UpdateOperationsService,
    public appState: AppStateService) {
    this.appState.setSidebarSection.subscribe(value => {
      this.activeMenuItem = value;
    });
  }

  ngOnInit(): void {
    this.initDialog();


  }

  public setActiveMenuItem(item: string): void {
    this.activeMenuItem = item;

  }


  public navigateByUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  public redirectLink(link: string, type?: string, reloadOperations?: string) {
    if (type) {
      this.router.navigate([link], {queryParams: {type: type}});
    } else {
      this.router.navigate([link]);
    }

    if (this.advancedSearchService?.searchModel) {
      this.advancedSearchService.searchModel.page = 1;
    }

    if (reloadOperations) {
      this.advancedSearchService.searchModel.operationType = reloadOperations;
      this.appState.applyFilters$.emit();
    } else {
      this.advancedSearchService.searchModel.operationType = null;
      this.appState.applyFilters$.emit();
    }

  }

  openUpdate() {
    const dialogRef = this.dialog.open(UpdateOperationListComponent, {
      height: 'auto',
      width: '650px',
      disableClose: true,
      panelClass: 'my-custom-dialog-class'
    });
  }

  private initDialog() {
    this.updateOperationsService.close
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp => {
        this.dialog.closeAll();
      }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
