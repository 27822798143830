import {Component, Inject, OnInit} from '@angular/core';
import {UserModel} from "../../../user-management/models/user-model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OperationService} from "../../../operation/service/operation.service";
import {TaskService} from "../../services/task.service";
import {DialogBase} from "../../../shared/abstractions/dialog-base";
import {TaskModel} from "../../models/task.model";
import {TaskStatusObjectModel} from "../../../settings/models/task-status-object-model";
import {SettingsService} from "../../../settings/services/settings.service";
import {PhaseObjectModel} from "../../../settings/models/phase-object-model";

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent extends DialogBase {
  public task: TaskModel;
  private readonly id: number;
  public users: UserModel[];
  public selected_user: UserModel;
  public statuses: TaskStatusObjectModel[];
  public selected_status: TaskStatusObjectModel;
  public phases: PhaseObjectModel[];
  public selected_phase: PhaseObjectModel;

  constructor(
    public taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) data,
    private operationService: OperationService,
    private dialogRef: MatDialogRef<TaskFormComponent>,
    private settingsService: SettingsService
  ) {
    super();
    this.id = data.id;
    this.task = data.task;
    if (!this.task.id_operation) {
      this.task.id_operation = data.operationId;
    }

    this.operationService.getActiveUsers().subscribe((users) => {
      this.users = users;
      if (this.task.assigned_to) {
        this.selected_user = users.find(user => user.id == this.task.assigned_to)
      }
    });

    this.settingsService.selectedTable = "Phase";
    this.settingsService.getData().subscribe((phases) => {
      this.phases = phases;
      if (this.task.assigned_to) {
        this.selected_phase = phases.find(phase => phase.id == this.task.id_phase)
      }
    });

    this.taskService.getStatuses().subscribe((statuses) => {
      this.statuses = statuses.data;
      if (this.task.id_task_status) {
        this.selected_status = statuses.data.find(status => status.id == this.task.id_task_status)
      } else {
        this.selected_status = statuses.data[0]
      }
    });
  }

  public cancel(): void {
    this.dialogRef.close(0);
  }

  public saveTask(): void {
    this.isCalled = true;
    this.task.assigned_to = Number.parseInt(this.selected_user.id);
    this.task.id_task_status = this.selected_status.id;
    this.task.id_phase = this.selected_phase?.id;
    this.taskService.saveTask(this.task.id_operation, this.task)
      .subscribe(value => {
        if (value.result) {
          if (this.id == 0) {
            this.task.id = value.data.id;
          }

          this.task.fullname = this.selected_user ? this.selected_user.name + " " + this.selected_user.surname : "";
          this.task.status = this.selected_status.name;
          this.task.status_color = this.selected_status.color;
          this.task.phase = this.selected_phase?.name;

          this.dialogRef.close(this.task);
        } else {
          this.dialogRef.close(0);
        }
      });
  }

}
