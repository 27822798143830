import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OperationListComponent } from './components/operation-list/operation-list.component';
import { SharedModule } from '../shared/shared.module';
import { OperationRoutingModule } from './operation-routing.module';
import { OperationDetailsComponent } from './components/operation-details/operation-details.component';
import { OperationService } from './service/operation.service';
import { AdvancedSearchService } from './service/advanced-search-service';
import { HttpClientService } from '../shared/services/http-client.service';
import { CardsComponent } from './components/cards/cards.component';
import { OperationAddComponent } from './components/operation-add/operation-add.component';
import { OperationEditComponent } from './components/operation-edit/operation-edit.component';
import { HousingModule } from '../housing/housing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FundingModule } from '../funding/funding.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import {VigilancePointModule} from '../vigilance-point/vigilance-point.module';
import {ObservationsMainModule} from '../observations-main/observations-main.module';
import { GrantAccessModalComponent } from './components/grant-access-modal/grant-access-modal.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';

@NgModule({
  declarations: [
    OperationListComponent,
    OperationDetailsComponent,
    CardsComponent,
    OperationAddComponent,
    OperationEditComponent,
    GrantAccessModalComponent,
    AdvancedSearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    HousingModule,
    FundingModule,
    VigilancePointModule,
    ObservationsMainModule,
    FileUploadModule,
    OperationRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    HttpClientService,
    OperationService,
    AdvancedSearchService
  ]
})
export class OperationModule { }
