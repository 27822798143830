import { NumericPipe } from './pipes/numeric.pipe';
import {NgModule} from '@angular/core';
import {AccordionDirective} from './directives/accordion.directive';
import {ToggleDirective} from './directives/toggle.directive';
import {AccordionComponent} from './components/accordion/accordion.component';
import {CommonModule} from '@angular/common';
import {AuthService} from './services/auth.service';
import {DataStorageService} from './services/data-storage.service';
import {HttpClientService} from './services/http-client.service';
import {LoginService} from '../login/service/login.service';
import {AdvancedSearchComponent} from './components/advanced-search/advanced-search.component';
import {UserService} from './services/user.service';
import {CityService} from './services/city.service';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {FinancialPipe} from './pipes/financial.pipe';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {DragAndDropDirective} from './directives/drag-and-drop.directive';
import {MatRadioModule} from '@angular/material/radio';
import {AccordionModule} from "ngx-bootstrap/accordion";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ProgressComponent} from './components/progress/progress.component';
import {NgxMaskModule} from "ngx-mask";
import {MatButtonModule} from "@angular/material/button";
import {FormlyModule} from "@ngx-formly/core";
import {PopupComponent} from './components/popup/popup.component';
import {NgxUiLoaderModule} from "ngx-ui-loader";
import {AppStateService} from "./services/app-state.service";
import { SpotButtonComponent } from './components/spot-button/spot-button.component';
import { GenericSidebarComponent } from './components/generic-sidebar/generic-sidebar.component';
import { FrenchNumberPipe } from './pipes/french-number.pipe';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import {GlobalComponentsModule} from '../global-components/global-components.module';

@NgModule({
  declarations: [ToggleDirective, AccordionDirective, AccordionComponent, AdvancedSearchComponent, FinancialPipe, FrenchNumberPipe, NumericPipe, DragAndDropDirective, ProgressComponent, PopupComponent, SpotButtonComponent, GenericSidebarComponent, FrenchNumberPipe, GenericModalComponent],
  imports: [
    GlobalComponentsModule,
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatDatepickerModule,
    MatRadioModule,
    TranslateModule,
    MatButtonModule,
    MatProgressBarModule,
    AccordionModule.forRoot(),
    NgxMaskModule.forRoot(),
    FormlyModule,
    ReactiveFormsModule,
    NgxUiLoaderModule
  ],
  providers: [HttpClientService, AuthService, DataStorageService, LoginService, UserService, CityService],
  exports: [
    ToggleDirective,
    AccordionDirective,
    DragAndDropDirective,
    AccordionComponent,
    AdvancedSearchComponent,
    FinancialPipe,
    FrenchNumberPipe,
    NumericPipe,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    NgMultiSelectDropDownModule,
    MatDatepickerModule,
    TranslateModule,
    MatProgressBarModule,
    AccordionModule,
    ProgressComponent,
    NgxMaskModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    PopupComponent,
    NgxUiLoaderModule,
    SpotButtonComponent,
    GenericSidebarComponent,
    GenericModalComponent,
    GlobalComponentsModule,
  ]
})
export class SharedModule {
}
