import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FileService} from '../service/file-service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DialogBase} from "../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.scss']
})
export class AddFileComponent extends DialogBase implements OnInit, OnDestroy {
  public data: any = {};
  public isError = false;
  private token: string;
  public withDesc: boolean;
  private action: string;

  constructor(public translate: TranslateService, private fileService: FileService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.clear();
    this.loadData(data);
  }

  ngOnInit(): void {
  }

  public loadData(data) {
    this.token = data.token;
    this.withDesc = data.hasOwnProperty('withDesc') ? data.withDesc : true;
    this.action = data.hasOwnProperty('action') ? data.action : "/operations/uploadoperationfile";
    this.data.params = data;
  }

  public cancel(): void {
    this.fileService.closeFile.next();
  }

  public setError(state) {
    this.isError = state;
    if (this.isError) {
      this.clear();
    }
  }

  public clear(): void {
    this.data.file = {};
  }

  public addFile(): void {
    this.data.params.token = this.token;
    this.data.token = this.token;
    this.isCalled = true;
    this.fileService.uploadFile(this.action, this.data, this.data.params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value.result == 'true' || value.result) {
          this.fileService.addFileList.next(value.data);
          this.fileService.closeFile.next(value.data);
          this.cancel();
        }
      });

  }

  uploadFile(files: any) {
    this.data.file = null;
    this.data.file = files[0];
    this.setError(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
