import {Component, Inject, OnInit} from '@angular/core';
import {DialogBase} from "../../../shared/abstractions/dialog-base";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ValidationService} from "../../services/validation.service";
import {ValidationModel} from "../../models/validation-model";
import {UserModel} from "../../../user-management/models/user-model";
import {OperationService} from "../../../operation/service/operation.service";

@Component({
  selector: 'app-validation-form',
  templateUrl: './validation-form.component.html',
  styleUrls: ['./validation-form.component.scss']
})
export class ValidationFormComponent extends DialogBase {
  public validation: ValidationModel;
  private id: number;
  public users: UserModel[];
  public selected_user: UserModel;

  constructor(
    public validationService: ValidationService,
    @Inject(MAT_DIALOG_DATA) data,
    private operationService: OperationService,
    private dialogRef: MatDialogRef<ValidationFormComponent>
  ) {
    super();
    this.id = data.id;
    this.validation = data.validation;
    if (!this.validation.id_operation) {
      this.validation.id_operation = data.operationId;
    }

    this.operationService.getActiveUsers().subscribe((users) => {
      this.users = users;
      if (this.validation.id_user) {
        this.selected_user = users.find(user => user.id == this.validation.id_user)
      }
    });
  }

  public cancel(): void {
    this.dialogRef.close(0);
  }

  public saveValidationStep(): void {
    this.isCalled = true;
    this.validation.id_user = this.selected_user.id;
    this.validationService.saveValidationStep(this.validation.id_operation, this.validation)
      .subscribe(value => {
        if (value.result) {
          if (this.id == 0) {
            this.validation.id = value.data.id;
          }

          this.validation.fullname = this.selected_user.name + " " + this.selected_user.surname;

          this.dialogRef.close(this.validation);
        } else {
          this.dialogRef.close(0);
        }
      });
  }

}
