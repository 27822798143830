<div class="left-sidebar">
  <div class="logo">
    <img src="{{cdn_url}}/assets/icons/{{logo_url}}"/>
  </div>

  <ul>
    <li (click)="setActiveMenuItem('dashboard')" (click)="redirectLink('/dashboard')"
        class="statistic">
      <div [ngClass]="{'active-item': activeMenuItem === 'dashboard'}" class="title-item">
        <img *ngIf="activeMenuItem==='dashboard';else inactiveDashboard"
             alt="" src="{{cdn_url}}/assets/icons/sidebar-dashboard-purple.svg" width="19px">
        <ng-template #inactiveDashboard>
          <img alt="" src="{{cdn_url}}/assets/icons/sidebar-dashboard-inactive.svg" width="19px">
        </ng-template>
        <span><a [routerLink]="['/dashboard']">{{'left-sidebar.dashboard' | translate}}</a></span>
      </div>
    </li>

    <li>
      <app-accordion [class]="['white-bg', 'menu']" [ngClass]="{'active-item': activeMenuItem === 'operations'}">
        <div class="title-item">
          <img *ngIf="activeMenuItem==='operations';else inactiveOp"
               alt="" src="{{cdn_url}}/assets/icons/operations-menu-purple.svg" width="19px">
          <ng-template #inactiveOp>
            <img alt="" src="{{cdn_url}}/assets/icons/operations-inactive.svg" width="19px">
          </ng-template>
          <span><a [routerLink]="['/operations']">{{ 'left-sidebar.operations' | translate }}</a></span>
        </div>
        <div class="panel-content">
          <div (click)="setActiveMenuItem('operations')" (click)="redirectLink('/operations', 'all')" class="sub-menu">
            {{ 'left-sidebar.all' | translate }}
          </div>
          <div (click)="setActiveMenuItem('operations')" (click)="redirectLink('/operations', 'user')"
               *ngIf="authService.isUserClient()"
               class="sub-menu">
            {{ 'left-sidebar.my-operations' | translate }}
          </div>
          <!--
          <div (click)="setActiveMenuItem('operations')" (click)="redirectLink('/operations/boamp', 'all', 'boamp')"
               *ngIf="authService.isUserAdmin()"
                class="sub-menu">
            {{ 'left-sidebar.boamp' | translate }}
          </div>
          <div (click)="setActiveMenuItem('operations')" (click)="redirectLink('/operations/joue', 'all', 'joue')"
               *ngIf="authService.isUserAdmin()"
                class="sub-menu">
            {{ 'left-sidebar.joue' | translate }}
          </div>
          -->
          <div (click)="setActiveMenuItem('operations')"
               (click)="redirectLink('/operations/ongoing', 'all', 'ongoing')"
               class="sub-menu">
            {{ 'left-sidebar.ongoing' | translate }}
          </div>
          <!--
          <div (click)="setActiveMenuItem('operations')" (click)="redirectLink('/operations/deleted', 'all', 'deleted')"
               *ngIf="authService.isUserAdmin()"
               class="sub-menu">
            {{ 'left-sidebar.deleted' | translate }}
          </div>

          <div (click)="setActiveMenuItem('activity')" (click)="redirectLink('/activity')"
               *ngIf="authService.isUserAdmin()"
               class="sub-menu">
            {{ 'left-sidebar.activity' | translate }}
          </div>
          -->
          <div (click)="setActiveMenuItem('tasks')" (click)="redirectLink('/tasks')"
               *ngIf="authService.isUserAdmin()"
               class="sub-menu">
            {{ 'left-sidebar.tasks' | translate }}
          </div>
          <div (click)="setActiveMenuItem('vigilance')" (click)="redirectLink('/vigilances')"
               *ngIf="authService.isUserAdmin()"
               class="sub-menu">
            {{ 'left-sidebar.vigilance' | translate }}
          </div>
          <!--
          <div (click)="openUpdate()" *ngIf="!appState.isSpotProject"
               class="sub-menu">
            {{ 'left-sidebar.update' | translate }}

          </div>
        -->
        </div>
      </app-accordion>
    </li>
    <li (click)="setActiveMenuItem('statistics')" (click)="redirectLink('/statistics')" *ngIf="!authService.isUserEnterprise()"
        class="statistic">
      <div [ngClass]="{'active-item': activeMenuItem === 'statistics'}" class="title-item">
        <img *ngIf="activeMenuItem==='statistics';else inactiveStat" alt="" src="{{cdn_url}}/assets/icons/statistic-menu-purple.svg" width="19px">
        <ng-template #inactiveStat>
          <img alt="" src="{{cdn_url}}/assets/icons/statistic-menu-inactive.svg" width="19px">
        </ng-template>
        <span><a [routerLink]="['/statistics']">{{ 'left-sidebar.statistics' | translate }}</a></span>
      </div>
    </li>
    <!--
    <li (click)="setActiveMenuItem('collect')" (click)="redirectLink('/collect')"
        class="statistic">
      <div [ngClass]="{'active-item': activeMenuItem === 'collect'}" class="title-item">
        <img *ngIf="activeMenuItem==='collect';else inactiveImport" alt="" src="{{cdn_url}}/assets/icons/upload.svg" width="19px">
        <ng-template #inactiveImport>
          <img alt="" src="{{cdn_url}}/assets/icons/upload.svg" width="19px">
        </ng-template>
        <span><a [routerLink]="['/collect']">{{ 'left-sidebar.import' | translate }}</a></span>
      </div>
    </li>
  -->
    <li (click)="setActiveMenuItem('rapports')" *ngIf="authService.isUserAdmin()">
      <app-accordion [class]="['white-bg', 'menu']" [ngClass]="{'active-item': activeMenuItem === 'rapports'}">
        <div class="title-item">
          <img *ngIf="activeMenuItem==='rapports';else inactiveDs" alt="" src="{{cdn_url}}/assets/icons/data-stream-menu-purple.svg" width="19px">
          <ng-template #inactiveDs>
            <img alt="" src="{{cdn_url}}/assets/icons/data-stream-menu-inactive.svg" width="19px">
          </ng-template>
          <span>{{ 'left-sidebar.report' | translate }}</span>
        </div>
        <div class="panel-content">
          <div (click)="redirectLink('/newsletters/template')" *ngIf="authService.isUserAdmin()"
               class="sub-menu">
            <span><a [routerLink]="['/newsletters/template']">{{ 'left-sidebar.datastream' | translate }}</a></span>
          </div>
          <!--<div class="sub-menu" *ngIf="authService.isUserAdmin()"
               (click)="redirectLink('/conflicts/templates/simko_conflict')">
            <span><a [routerLink]="['/conflicts/templates/simko_conflict']">{{ 'left-sidebar.conflict-report' | translate }}</a></span>
          </div>-->
        </div>
      </app-accordion>
    </li>

    <li>
      <app-accordion [class]="['white-bg', 'menu']" [ngClass]="{'active-item': activeMenuItem === 'plus'}">
        <div class="title-item">
          <span>Plus</span>
        </div>
        <div class="panel-content">
          <!--
          <div (click)="setActiveMenuItem('plus')" (click)="redirectLink('/plans')" class="sub-menu">
            {{ 'left-sidebar.plans' | translate }}
          </div>
        -->
          <div (click)="setActiveMenuItem('plus')" (click)="redirectLink('/guide')" class="sub-menu">
            {{ 'left-sidebar.guide' | translate }}
          </div>
          <div *ngIf="!appState.isSpotProject" (click)="setActiveMenuItem('plus')" (click)="redirectLink('/enterprises')" class="sub-menu">
            {{ 'left-sidebar.enterprises' | translate }}
          </div>
          <div *ngIf="!appState.isSpotProject" (click)="setActiveMenuItem('plus')" (click)="redirectLink('/clients')" class="sub-menu">
            {{ 'left-sidebar.clients' | translate }}
          </div>

        </div>
      </app-accordion>
    </li>
  </ul>
</div>
