import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {LoginService} from './service/login.service';
import * as constant from '../app.constant';
import {UserLoginModel} from './models/user-login-model';
import {AuthService} from '../shared/services/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public user: UserLoginModel = {};
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public logo_url: string = constant.ACTIVE_PROJECT.sidebar_logo;

  constructor(private router: Router, private loginService: LoginService,
              private authService: AuthService, private translate: TranslateService) {
  }

  ngOnInit() {
  }

  public redirect(url: string): void {
    this.router.navigateByUrl(url);
  }

  public login(): void {
    this.loginService.login(this.user)
      .subscribe((data) => {
        if (data.result !== "true") {
          Swal('Oops...', data.message, 'error');
        } else {
          this.authService.setLocalStorage('id', data.data.id);
          this.authService.setLocalStorage('token', data.data.token);
          this.authService.setLocalStorage('name', data.data.name);
          this.authService.setLocalStorage('surname', data.data.surname);
          this.authService.setLocalStorage('role', data.data.role);
          this.authService.setLocalStorage('client', data.data.client);

          if (data.data.role == "client") {
            this.router.navigate(['/dashboard'], {queryParams: {type: 'user'}});
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
  }
}
