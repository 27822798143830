import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FundingService} from '../service/funding-service';
import {format, parse} from 'date-fns';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogBase} from "../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-edit-funding',
  templateUrl: './edit-funding.component.html',
  styleUrls: ['./edit-funding.component.scss']
})
export class EditFundingComponent extends DialogBase implements OnInit {
  public funding: any = {};
  public index: number;

  constructor(public translate: TranslateService, private fundingService: FundingService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.loadDataLists(data);
  }

  ngOnInit(): void {
  }

  private loadDataLists(data): void {
    this.funding = {
      financial_organisation: data.funding.financial_organisation,
      type: data.funding.type,
      amount: data.funding.amount,
      submission_date: data.funding.submission_date,
      awarding_date: data.funding.awarding_date,

    };
    this.index = data.index;
    this.parseData();
  }


  public cancel(): void {
    this.fundingService.closeFunding.next();
  }

  public addFunding(): void {
    this.convertData();
    this.isCalled = true;
    this.fundingService.editFundingList.next({funding: this.funding, index: this.index});

    this.cancel();
  }

  private parseData() {
    if (this.funding.submission_date.includes('-')) {
      this.funding.submission_date = parse(this.funding.submission_date, 'yyyy-MM-dd', new Date());
      this.funding.awarding_date = parse(this.funding.awarding_date, 'yyyy-MM-dd', new Date());
    } else {
      this.funding.submission_date = parse(this.funding.submission_date, 'dd/MM/yyyy', new Date());
      this.funding.awarding_date = parse(this.funding.awarding_date, 'dd/MM/yyyy', new Date());
    }

  }

  private convertData() {
    this.funding.submission_date._d ?
      this.funding.submission_date = format(new Date(this.funding.submission_date._d), 'dd/MM/yyyy') :
      this.funding.submission_date = format(new Date(this.funding.submission_date), 'dd/MM/yyyy');

    this.funding.awarding_date._d ?
      this.funding.awarding_date = format(new Date(this.funding.awarding_date._d), 'dd/MM/yyyy') :
      this.funding.awarding_date = format(new Date(this.funding.awarding_date), 'dd/MM/yyyy');
  }

  amountChangeValidate(event: number, el: HTMLInputElement) {
    if (event < 0) {
      this.funding.amount = 0;
      el.value = '0';
    }
  }
}
