import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {OperationTableModel} from '../../model/operation-table-model';
import {AdvancedSearchService} from '../../service/advanced-search-service';
import {OperationService} from '../../service/operation.service';
import * as constant from '../../../app.constant';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {AppStateService} from '../../../shared/services/app-state.service';
import {AdvancedSearchComponent} from '../../../shared/components/advanced-search/advanced-search.component';
import {HeaderTitles} from '../../../shared/enums/header-titles';
import {GrantAccessModalComponent} from '../grant-access-modal/grant-access-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ListComponentModel} from '../../../shared/models/list-component-model';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {OperationSearchModel} from "../../../shared/models/operation-search-model";
import {OperationStatus, OperationStatusReversed} from "../../../shared/enums/status";
import {OperationActions} from "../../model/operation-actions.enum";
import {ReportApiService} from "../../../reports/services/report-api.service";

@Component({
  selector: 'app-operation-list',
  templateUrl: './operation-list.component.html',
  styleUrls: ['./operation-list.component.scss']
})
export class OperationListComponent extends ListComponentModel implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  public operationsModel: OperationTableModel;
  public showRight = false;
  private previousActiveRow: HTMLElement;
  public operationType: string;
  // Spot - Sector, Seigos - Market
  public marketProjectColumn = !constant.IS_SPOT_PROJECT;
  public selectedOperations: string[] = [];
  public getOperationsSubscription: Subscription;
  public sentType: string;
  public status = OperationStatusReversed;
  public statusOriginal = OperationStatus;
  @ViewChild('myBody') tBody: ElementRef;
  @ViewChildren('row') rows: QueryList<any>;


  constructor(public advancedSearchService: AdvancedSearchService,
              public authService: AuthService,
              private appStateService: AppStateService,
              private operationService: OperationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private changeDetector: ChangeDetectorRef,
              private ngZone: NgZone,
              private translate: TranslateService,
              private reportService: ReportApiService,
              private ngxLoader: NgxUiLoaderService) {
    super();
  }

  ngOnInit(): void {
    this.initFilter();
    this.getQueryparams();
    this.searchOperations();
    this.applyOperationsFilter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.operationService.isLoadingFinished = false;
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      // if (this.rows) {
      //   this.rows.changes.subscribe(t => {
          this.getOperationFromQuery();
      //   });
      // }
    })
  }

  ngAfterContentChecked() {
    this.calculateTableHeigth();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    setTimeout(() => {
      this.calculateTableHeigth();
    }, 300);
  }

  private getOperationFromQuery() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (!this.operationService.isLoadingFinished && value.operation) {
          this.operationDetailsClick(value.operation, null, null, null);
        }
      });
  }

  private initFilter() {
    this.appStateService.setHeaderText(HeaderTitles.Operations);
    this.appStateService.activeSearch = AdvancedSearchComponent;
    this.operationType = this.activatedRoute.snapshot.paramMap.get('type');
    const beforeLoad = this.activatedRoute.snapshot.queryParamMap.get('beforeLoad') == 'true';
    const params = this.parseQueryParamArgs(this.activatedRoute.snapshot.queryParams);
    if (beforeLoad) {
      return;
    }
    // this.advancedSearchService.checkFiltersReset();
    if (this.advancedSearchService?.searchModel?.advancedsearch) {
      const advancedSearch = this.advancedSearchService.searchModel.advancedsearch;
      const operations = this.advancedSearchService.searchModel.operations;
      const type = this.advancedSearchService.searchModel.type;
      this.advancedSearchService.searchModel = {
        page: 1,
        show: 50,
        orderby: 'spot_operation.id',
        order: 'desc',
        operationType: this.operationType,
        advancedsearch: advancedSearch,
        operations: operations,
        type: type
      };
    } else {
      this.advancedSearchService.searchModel = {
        page: 1,
        show: 50,
        orderby: 'spot_operation.id',
        order: 'desc',
        operationType: this.operationType,
        advancedsearch: params ?? new OperationSearchModel()
      };
    }
  }

  private parseQueryParamArgs(params: Params) {
    let search = new OperationSearchModel;
    Object.keys(params).forEach(value => {
      if (['operation', 'beforeLoad'].indexOf(value) != -1) {
        return;
      } else if (params[value]) {
        search[value] = params[value];
      }
    });
    return search;
  }

  public showSearch(): void {
    this.isSearchVisible = true;
  }

  public closeSearch(): void {
    this.isSearchVisible = false;
    this.search.setValue(null);
    this.advancedSearchService.searchModel.search = '';
    this.getOperationsList();
  }

  private getQueryparams(): void {
    this.advancedSearchService.setOperationType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type: string) => {
        if (type != 'skip') {
          this.advancedSearchService.searchModel.operations = type;
        }
        this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
        this.operationService.isLoadingFinished = false;
        this.getOperationsList();
      });
    this.operationService.detailsLoaded
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.scrollTable();
      });

    this.operationService.loadRelatedOperation
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.operationDetailsClick(value, null, null, null);
      });
  }

  public setTableItemsEndCount(): number {
    let endCount = this.advancedSearchService.searchModel.show * this.advancedSearchService.searchModel.page;
    if (endCount > Number(this.operationsModel?.allcount)) {
      endCount = Number(this.operationsModel?.allcount);
    }
    return endCount;
  }

  public searchOperations(): void {
    this.search.valueChanges
      .pipe(
        // debounce input for 400 milliseconds
        debounceTime(500),
        // only emit if emission is different from previous emission
        distinctUntilChanged(),
        switchMap((res) => this.searchAction(res))
      ).subscribe((resp: OperationTableModel) => {
      this.isLoaded = true;
      this.operationsModel = resp;
      this.advancedSearchService.searchModel.page = resp.pageId;
    });
  }

  private searchAction(res): Observable<any> {
    this.isLoaded = false;
    this.advancedSearchService.searchModel.search = res;
    this.advancedSearchService.searchModel.page = 1;
    this.advancedSearchService.searchModel.show = 50;
    return this.operationService.getOperationsList();
  }

  private applyOperationsFilter(): void {
    this.appStateService.applyFilters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.appStateService.activeSearch === AdvancedSearchComponent) {
          this.advancedSearchService.searchModel.page = 1;
          this.getOperationsList();
          this.operationType = this.advancedSearchService.searchModel.operationType;
          this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
        }
      });
  }

  private getOperationsList(): void {

    this.isLoaded = false;
    this.ngxLoader.startLoader('op_list');
    if (this.sentType && this.advancedSearchService.searchModel.operations && this.sentType != this.advancedSearchService.searchModel.operations) {
      this.getOperationsSubscription.unsubscribe();
      this.isRequestSend = false;
    }
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      this.sentType = this.advancedSearchService.searchModel.operations;
      this.getOperationsSubscription = this.operationService.getOperationsList()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: OperationTableModel) => {
          this.isLoaded = true;
          this.isRequestSend = false;
          this.operationsModel = resp;
          this.advancedSearchService.searchModel.page = resp.pageId;
          this.calculateTableHeigth();
          this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
          this.ngxLoader.stopLoader('op_list');
        });
    }
  }

  public sortColumn(column: string, elem?: HTMLElement): void {
    if (column !== this.advancedSearchService.searchModel.orderby) {
      this.advancedSearchService.searchModel.orderby = column;
      this.advancedSearchService.searchModel.order = 'asc';
      this.setActiveColumn(elem, 'asc');
    } else {
      if (this.advancedSearchService.searchModel.order === 'asc') {
        this.advancedSearchService.searchModel.order = 'desc';
        this.setActiveColumn(elem, 'desc');
      } else {
        this.advancedSearchService.searchModel.order = 'asc';
        this.setActiveColumn(elem, 'asc');
      }
    }
    this.getOperationsList();
  }

  public selectPage(pagesCount: number): void {
    this.advancedSearchService.searchModel.show = pagesCount;
    this.getOperationsList();
  }

  public setActiveColumn(activeColumn: HTMLElement, order: string) {

    if (this.previousActiveColumn) {
      this.previousActiveColumn.classList.remove('active');
      this.previousActiveColumn.getElementsByClassName('asc')[0].classList.remove('active');
      this.previousActiveColumn.getElementsByClassName('desc')[0].classList.remove('active');
    }

    activeColumn.classList.add('active');
    activeColumn.getElementsByClassName(order)[0].classList.add('active');
    this.previousActiveColumn = activeColumn;
  }

  public calculateTableHeigth() {
    if (document.getElementById('body') && document.getElementById('pagination')) {
      const top = document.getElementById('body').getBoundingClientRect().top;
      const bottom = document.getElementById('pagination').getBoundingClientRect().height;
      document.getElementById('body').style.maxHeight = `calc(100vh - ${top + bottom + 2 + 'px'})`;
      document.getElementById('body').style.height = `calc(100vh - ${top + bottom + 2 + 'px'})`;
    }
  }

  public nextPage() {
    if (this.operationsModel.pages === this.advancedSearchService.searchModel.page) {
      return;
    }
    this.advancedSearchService.searchModel.page = this.advancedSearchService.searchModel.page + 1;
    this.getOperationsList();
  }

  public previousPage() {
    if (this.advancedSearchService.searchModel.page === 1) {
      return;
    }
    this.advancedSearchService.searchModel.page = this.advancedSearchService.searchModel.page - 1;
    this.getOperationsList();
  }

  public operationDetailsClick(id: string, event: Event, checkbox: HTMLElement, row: HTMLElement) {
    if (checkbox && event?.target == checkbox) {
      return;
    }
    this.operationService.operationClick$.next(id);
    let rowDp = null;
    if (!row) {
      const index = this.operationsModel?.operations?.findIndex(value => value.id == id);
      row = this.tBody?.nativeElement?.children[index];
      rowDp = row;
    }
    if (this.previousActiveRow) {
      this.previousActiveRow.classList.remove('active');
    }
    row?.classList.add('active');
    this.previousActiveRow = row;
    if (event?.target != checkbox) {
      this.showRightPanel(row);
    } else if (!event && !checkbox) {
      this.showRightPanel(rowDp);
    }
  }

  public showRightPanel(row = this.previousActiveRow): void {
    this.showRight = true;
    this.calculateTableHeigth();
    // this.scrollTable(row);
  }

  private scrollTable(row = this.previousActiveRow) {
    if (row) {
      row.parentElement.scrollTo(0, row?.offsetTop - row?.parentElement.clientHeight / 2);
    }
  }

  public closeOperation(): void {
    this.showRight = false;
    this.operationService.isLoadingFinished = true;
    if (this.previousActiveRow) {
      this.previousActiveRow.classList.remove('active');
    }
    this.calculateTableHeigth();

  }

  public checkAllOperations(checked: boolean): void {
    const allCheckboxes = document.getElementsByClassName('check-operation');
    if (checked) {
      this.setChecked(true, allCheckboxes);
      this.operationsModel.operations.forEach(operation => {
        this.selectedOperations.push(operation.id);
      });
    } else {
      this.setChecked(false, allCheckboxes);
      this.selectedOperations = [];
    }
  }

  public setChecked(checked: boolean, all): void {
    for (let i = 0; i < all.length; i++) {
      all[i].checked = checked;
    }
  }

  public checkOperation(checked: boolean, operationId: string): void {
    if (checked) {
      this.selectedOperations.push(operationId);
    } else {
      if (this.selectedOperations.includes(operationId)) {
        const i = this.selectedOperations.indexOf(operationId);
        this.selectedOperations.splice(i, 1);
      }
    }
  }

  public validateOperation(): void {
    this.actionOperation(OperationActions.Validate);
  }


  public grantAccess(): void {
    if (this.selectedOperations.length == 0) {
      Swal('oops', this.translate.instant('operation-list.please-check'), 'error');
      return;
    }
    const operations = this.operationsModel.operations.filter(value => this.selectedOperations.includes(value.id));
    const dialogRef = this.dialog.open(GrantAccessModalComponent, {
      height: 'auto',
      width: '650px',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        operations: operations
      }
    });
  }


  public restoreOperation(): void {
    this.actionOperation(OperationActions.Restore);
  }

  public deleteOperation(): void {
    this.actionOperation(OperationActions.Delete);
  }

  public downloadReport(): void {
    this.operationService.downloadReport()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data.success == 'true') {
          window.open(this.api_url + '/temp/' + data.filename, '_blank');
          this.isLoaded = true;
        }

      });
  }

  public exportPdf(): void {
    // if (this.selectedOperations.length > 0) {
    //   Swal({
    //     title: this.translate.instant('operation-list.pdf-report'),
    //     text: this.translate.instant('operation-list.create-pdf'),
    //     type: 'success',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     cancelButtonText: this.translate.instant('operation-list.cancel'),
    //     confirmButtonText: this.translate.instant('operation-list.ok')
    //   }).then((result) => {
    //     if (result.value) {
    //       const selectedOperations = this.selectedOperations.join(',');
    //       window.open(this.api_url + '/createpdf.php?donneesClient=' + selectedOperations, '_blank');
    //       this.selectedOperations = [];
    //     }
    //   });
    // } else {
    //   Swal('oops', this.translate.instant('operation-list.please-check'), 'error');
    // }
  }

  public exportPdfReport() {
    if (this.selectedOperations.length > 0) {
      // Swal({
      //   title: this.translate.instant('operation-list.pdf-report'),
      //   text: this.translate.instant('operation-list.create-pdf'),
      //   type: 'success',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   cancelButtonText: this.translate.instant('operation-list.cancel'),
      //   confirmButtonText: this.translate.instant('operation-list.ok')
      // }).then(result => {
      //   if (result.value) {
          const selectedOperations = this.selectedOperations.join(',');
          // window.open(this.api_url + '/createpdf.php?donneesClient=' + selectedOperations, '_blank');
          this.reportService.loadPdfReportData(this.selectedOperations.map(v => Number.parseInt(v)));
          this.router.navigateByUrl('/report');
          this.selectedOperations = [];
      //   }
      // });
    } else {
      Swal('oops', this.translate.instant('operation-list.please-check'), 'error');
    }
  }

  public actionOperation(action: string): void {
    if (this.selectedOperations.length > 0) {
      Swal({
        title: this.translate.instant('operation-list.' + action + 'Title'),
        text: this.translate.instant('operation-list.' + action + 'Question'),
        type: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('operation-list.cancel'),
        confirmButtonText: this.translate.instant('operation-list.ok')
      }).then((result) => {
        if (result.value) {
          if (action == OperationActions.Validate) {
            this.selectedOperations = this.getToValidateSelectedOperations();
          }
          this.operationService.actionOperation(this.selectedOperations, action)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              if (data.message) {
                Swal('oops', data.message, 'error');
              } else {
                this.getOperationsList();
                this.selectedOperations = [];
                const elem: any = document.getElementById('check-all');
                elem.checked = false;
              }
            });
        }

      });
    } else {
      //Swal('oops', 'please check one checkbox', 'error');
      Swal('oops', this.translate.instant('operation-list.please-check'), 'error');
    }
  }

  addOperation() {
    this.router.navigateByUrl('/operations/add');
  }

  isSelectedOperationToValidate(): boolean {
    const operations = this.getToValidateSelectedOperations();
    return !!(Array.isArray(operations) && operations.length);
  }

  /**
   * This funtion will return the ids of selected operations with status 'To validate'
   * @private
   */
  private getToValidateSelectedOperations(): string[] {
    if (!this.operationsModel?.operations || !this.selectedOperations) {
      return;
    }
    return this.operationsModel?.operations
      .filter(value => this.selectedOperations.includes(value.id)
        && (value.status == this.statusOriginal['À valider']
          || value.status == this.statusOriginal['BOAMP']))
      .map(value => value.id);
  }

  editOperation(id: any) {
    this.router.navigateByUrl(`/operations/edit/${id}`);
  }

  simulate() {
    if (this.selectedOperations.length > 0) {
      this.operationService.addSimulatedValues(this.selectedOperations)
    } else {
      Swal('oops', this.translate.instant('operation-list.please-check'), 'error');
    }
  }
}
