import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClientService} from 'src/app/shared/services/http-client.service';

@Injectable()
export class FileService {
  public closeFile = new Subject<any>();
  public addFileList = new Subject<any>();
  public editFileList = new Subject<any>();

  constructor(private httpClient: HttpClientService) {
  }

  public getFileList(): Observable<any> {
    return this.httpClient.post('/operations/getoperationfiles');
  }

  public addFile(param): Observable<any> {

    const formData = new FormData();
    formData.append('file', param.file);
    formData.append('operation_token', param.token);
    formData.append('description', param.description);

    return this.httpClient.postFormData('/operations/uploadoperationfile', formData);
  }

  uploadFile(action, params, opts):Observable<any> {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('description', params.hasOwnProperty('description') ? params.description : "");
    if (params.hasOwnProperty('token')) {
      formData.append('operation_token', params.token);
    }
    if (opts.hasOwnProperty('data')) {
      for (let data in opts.data) {
        formData.append(data, opts.data[data]);
      }
    }

    return this.httpClient.postFormData(action, formData);
  }

  public deleteFile(param): Observable<any> {
    let params = {
      id: param.id,
      type: param.type
    };
    return this.httpClient.post('/operations/deleteoperationfile', params);
  }
}
