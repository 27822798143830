import {IConfig, NON_WHITESPACE_REGEX} from "./IConfig";
import {FormGroup} from "@angular/forms";
import {EnterpriseObjectModel} from "../models/enterprise-object-model";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {HttpClientService} from "../../shared/services/http-client.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

export class EnterpriseConfig implements IConfig {
  columns = ['id', 'enterprise'];
  form = new FormGroup({});
  model = new EnterpriseObjectModel();
  name = 'enterprise';
  defaultHttpParams = {role: 'enterprise', orderby: 'id', order: 'desc'};
  fields: FormlyFieldConfig[];

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.translate.get(['settings.enterprise', 'settings.enterprise-help']).subscribe(value => {
      this.fields = [
        {
          key: 'name',
          type: 'input',
          name: 'enterprise',
          templateOptions: {
            label: value['settings.enterprise'],
            placeholder: value['settings.enterprise-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        }
      ];

    });
  }

  getData(params = null): Observable<any> {
    if (!params) {
      params = this.defaultHttpParams;
    }
    return this.http.post('/users/getcliententerprisebyrole', params).pipe(map(value => value.data));
  }

  addItem(): Observable<boolean> {
    return this.http.post('/users/addnewcliententerprise', {...this.defaultHttpParams, ...this.model}).pipe(map(value => value.success));
  }

  editItem(): Observable<boolean> {
    return this.http.post('/users/editcliententerprise', {...this.defaultHttpParams, ...this.model}).pipe(map(value => value.success));
  }

  getItemData(id): Observable<any> {
    return this.http.post('/users/getcliententerprise', {id: id}).pipe(map(value => {
      return {
        public_private: value?.user?.public_private,
        id: value?.user?.id,
        role: this.defaultHttpParams.role,
        name: value?.user?.name,
        type_of_organization: value?.user?.type_of_organization
      };
    }));
  }

  deleteItem(id): Observable<boolean> {
    return this.http.post('/users/delete', {id: id}).pipe(map(value => value.success));
  }

  setOrder(field) {
    // check if the field is ID
    if (field === 'id') {
      this.setOrderParameter(field);
    } else {
      // otherwise get name from the form fields
      const name = this.fields.find(value => value.name === field)?.key.toString();
      if (name) {
        this.setOrderParameter(name);
      } else {
        this.setOrderParameter(field);
      }
    }
  }

  private setOrderParameter(newParameter) {
    if (this.defaultHttpParams.orderby === newParameter) {
      this.setOrderType();
    } else {
      this.defaultHttpParams.orderby = newParameter;
    }
  }

  private setOrderType() {
    if (this.defaultHttpParams.order === 'desc') {
      this.defaultHttpParams.order = 'asc';
    } else {
      this.defaultHttpParams.order = 'desc';
    }
  }
}

