import {DatePipe} from '@angular/common';
import {ChangeDetectorRef, Component, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {AuthService} from 'src/app/shared/services/auth.service';
import {ObservationModel} from '../../model/observation-model';
import {OperationDataModel} from '../../model/operation-model';
import {OperationResultModel} from '../../model/operation-result-model';
import {OperationService} from '../../service/operation.service';
import * as constant from '../../../app.constant';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {UserWatchModel} from "../../model/user-watch-model";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import Swal from "sweetalert2";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {HousingDetailsModel} from "../../model/housing-details-model";
import {FinancialDetailsModel} from "../../model/financial-details-model";
import {OperationStatusReversed} from "../../../shared/enums/status";
import {StepperValues} from "../../model/stepper-values";
import {ACTIVE_ENVIRONMENT} from "../../../app.constant";
import {PhaseObjectModel} from "../../../settings/models/phase-object-model";
import {SettingsService} from "../../../settings/services/settings.service";
import {PhaseConfig} from "../../../settings/configs/phase-config";
import {HttpClientService} from "../../../shared/services/http-client.service";
import {TableService} from '../../../global-components/table/services/table.service';

@Component({
  selector: 'app-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.scss']
})
export class OperationDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Output() close = new EventEmitter<void>();
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public api_url: string = constant.ACTIVE_ENVIRONMENT.api_url;
  public operation: OperationResultModel;
  public stepper: string[] = [];
  public phases: Observable<PhaseObjectModel[]>
  // public observations: ObservationModel[];
  public isLoaded = false;
  public generalInformation = [];
  public housing = [];
  public housingValues = [];
  public modifiedHousingValues = [];
  public status = OperationStatusReversed;
  public housingBuffer = [];
  public architectural = [];
  public mastery = [];
  public tenders = [];
  public market = [];
  public permit = [];
  public funding = [];
  public modifiedFundingValues = [];
  public activeUsersList$: Observable<any>;
  public watchOperationUsers$: Observable<UserWatchModel>;
  public watchUsersIds: string[];
  public multipleDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    enableCheckAll: false,
    textField: 'name',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };
  public changedSections = [];
  public isLogementShow = true;
  public georeferencing = [];
  private unsubscribe$ = new Subject<void>();

  constructor(public operationService: OperationService, public authService: AuthService,
              private changeDetector: ChangeDetectorRef, private ngZone: NgZone,
              private dialog: MatDialog, private datePipe: DatePipe,
              private translate: TranslateService, private router: Router,
              private ngxLoader: NgxUiLoaderService, private settingsService: SettingsService,
              private tableService: TableService) {
  }

  ngOnInit(): void {
    this.handleOperationClick();
    this.initIncomesAndExpenses()
    this.settingsService.selectedTable = "Phase";
    this.phases = this.settingsService.getData()
  }

  ngOnChanges(): void {
    this.initIncomesAndExpenses()
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public frenchFormat(number: string): string {
    if (!number || Number.isNaN(Number.parseInt(number)))
      return null;
    return Intl.NumberFormat('fr-FR').format(parseFloat(number)) + ' €';
  }

  public getChangedHousingValue(id, field) {
    if (!this.operation) {
      return null;
    }
    const newItem = this.operation.housing.find(value => value.housingId == id);
    if (!newItem) {
      return;
    }
    if (newItem.deleted == '1') {
      return newItem[field];
    }
    let index = -1;
    if (newItem.main_housing_id) {
      index = this.housingBuffer.findIndex(value => value.housingId == newItem.main_housing_id);
    } else {
      index = this.housingBuffer.findIndex(value => value.housingId == id);
    }
    //  try to find the old item for this id
    //  if the item exist
    if (index != -1) {
      //  if the new and old field value are equal
      if (newItem[field] == this.housingBuffer[index][field]) {
        return null;
      } else {
        //  return old value
        const value = this.housingBuffer[index][field];
        if (!value) {
          return '-';
        }
        return value;
      }
    } else {
      return null;
    }
  }

  public getChangedFundingValue(id, field) {
    let index = this.modifiedFundingValues.findIndex(value => value.main_financial_id == id);
    let item = this.funding.find(value => value.id == id);
    if (index != -1) {
      if (item[field] == this.modifiedFundingValues[index][field]) {
        return null;
      } else {
        return this.modifiedFundingValues[index][field];
      }
    } else {
      return null;
    }
  }

  public editOperation() {
    this.router.navigateByUrl(`/operations/edit/${this.operation.operationdata.id}`);
  }

  public closeOperation(): void {
    this.close.emit();
    this.operation = null;
  }

  public addWatchCurrentUser(event) {
    let id = this.getUserId(event);
    let call = !this.operation.is_watching ? this.operationService.addWatchOperationUser(id, this.operation.operationdata.id) :
      this.operationService.deleteWatchOperationUser(id, this.operation.operationdata.id);
    call.subscribe(value => {
      if (value.result == "true") {
        this.watchOperationUsers$ = null;
        this.loadWatchOperationUsers();
        this.operation.is_watching = !this.operation.is_watching;
      } else {
        Swal('Error', 'Something went wrong. Please, contact administrator.\n' + value.error, 'error');
      }
    });
  }

  public addWatchOperationUser(event) {
    let id = this.getUserId(event);

    this.operationService.addWatchOperationUser(id, this.operation.operationdata.id).subscribe(value => {
      if (value.result == "true") {
        this.watchOperationUsers$ = null;
        this.loadWatchOperationUsers();
        if (id == this.authService.getLocalStorage('id')) {
          this.operation.is_watching = !this.operation.is_watching;
        }
      } else {
        Swal('Error', 'Something went wrong. Please, contact administrator.\n' + value.error, 'error');
      }
    });
  }

  public deleteWatchOperationUser(event) {
    let id = this.getUserId(event);

    this.operationService.deleteWatchOperationUser(id, this.operation.operationdata.id).subscribe(value => {
      if (value.result == "true") {
        this.watchOperationUsers$ = null;
        this.loadWatchOperationUsers();
        if (id == this.authService.getLocalStorage('id')) {
          this.operation.is_watching = !this.operation.is_watching;
        }
      } else {
        Swal('Error', 'Delete watch user error.\n' + value.error, 'error');
      }
    });
  }

  goToOperationsDetails(id: any) {
    this.operationService.loadRelatedOperation.next(id);
  }

  public checkChangedSection(name): boolean {
    return this.changedSections.includes(name);
  }

  private handleOperationClick(): void {
    this.operationService.operationClick$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id: string) => {
        this.isLoaded = false;
        this.ngxLoader.startLoader('op_details');
        this.operationService.getOperation(id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((resp: any) => {
            this.operation = resp.result;
            if (this.operation.operationdata.status != "A valider") {
              this.operation.getchanged = null;
              this.operation.getchangedclient = null;
            }
            this.changedSections = [];

            if (!this.operation?.housing) {
              this.operation.housing = [];
            }

            this.buildGeneralInformationList(this.operation.operationdata, this.operation.getchanged, this.operation.client, this.operation.getchangedclient);
            this.buildStepper(this.operation.operationdata);
            this.buildHousingList(this.operation.operationdata, this.operation.getchanged);
            this.architecturalCompetitionList(this.operation.operationdata, this.operation.getchanged);
            this.subjectMasteryList(this.operation.operationdata, this.operation.getchanged);
            this.tendersList(this.operation.operationdata, this.operation.getchanged);
            this.marketList(this.operation.operationdata, this.operation.getchanged);
            this.permitList(this.operation.operationdata, this.operation.getchanged);
            this.fundingList(this.operation.financial, this.operation.getchangedfinancial);
            this.housingList(this.operation.housing, this.operation.getchangedhousing);
            this.georeferencingList(this.operation.operationdata, this.operation.getchanged);
            // this.getObservations(this.operation.operationdata);
            this.buildWatchOperationInfo();
            this.isLoaded = true;
            this.ngxLoader.stopLoader('op_details');
            this.operationService.detailsLoaded.next();
            this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
            setTimeout(() => {
              this.calculateTableHeigth();
            }, 0);
          });
      });
  }

  private buildWatchOperationInfo() {
    this.translate.get(['common.select-all', 'common.unselect-all', 'common.search']).subscribe(value => {
      this.multipleDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        enableCheckAll: false,
        textField: 'name',
        searchPlaceholderText: value['common.search'],
        itemsShowLimit: 0,
        allowSearchFilter: true
      };
      setTimeout(() => {
        this.activeUsersList$ = this.operationService.getActiveUsers().pipe(tap(x => {
          x.forEach(xx => {
            xx.full_name = xx.name + ', ' + xx.surname;
          });
        }));
        this.loadWatchOperationUsers();
      }, 0);

    });


  }

  private buildGeneralInformationList(operationdata: OperationDataModel, changedOperationdata: OperationDataModel, client, changedclient: any): void {
    this.generalInformation = [];
    this.generalInformation.push({
      name: this.translate.instant('operation-details.title'),
      oldvalue: operationdata?.name,
      newvalue: changedOperationdata?.name
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.description'),
      oldvalue: operationdata?.description,
      newvalue: changedOperationdata?.description
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.address'),
      oldvalue: operationdata?.gen_address,
      newvalue: changedOperationdata?.gen_address
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.locality'),
      oldvalue: operationdata?.op_location,
      newvalue: changedOperationdata?.op_location
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.type-of-work'),
      oldvalue: operationdata?.gen_type_work,
      newvalue: changedOperationdata?.gen_type_work
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.sector'),
      oldvalue: operationdata?.sector,
      newvalue: changedOperationdata?.sector
    });
    this.isLogementShow = operationdata?.sector == 'LOG';
    this.generalInformation.push({
      name: this.translate.instant('operation-details.status'),
      oldvalue: this.status[operationdata?.status],
      newvalue: this.status[changedOperationdata?.status]
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.surface'),
      oldvalue: operationdata?.gen_shonm2,
      newvalue: changedOperationdata?.gen_shonm2
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.visibility'),
      oldvalue: operationdata?.visibility == "1" ? 'Privée' : 'Publique',
      newvalue: changedOperationdata?.visibility == "1" ? 'Privée' : changedOperationdata?.visibility != null ? 'Publique' : null
    });
    this.generalInformation.push({
      name: this.translate.instant('operation-details.client-name'),
      oldvalue: client?.name,
      newvalue: changedclient?.name
    });
    if (this.generalInformation.some(value => value.newvalue)) {
      this.changedSections.push('general');
    }
  }

  private buildHousingList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.housing = [];
    this.housing.push({
      name: "Total Log",
      oldvalue: operationdata?.gen_number_houses,
      newvalue: changedOperationdata?.gen_number_houses
    });
    this.housing.push({name: "LLS", oldvalue: operationdata?.gen_LLS, newvalue: changedOperationdata?.gen_LLS});
    this.housing.push({name: "LLTS", oldvalue: operationdata?.gen_LLTS, newvalue: changedOperationdata?.gen_LLTS});
    this.housing.push({name: "PLS", oldvalue: operationdata?.gen_PLS, newvalue: changedOperationdata?.gen_PLS});
    this.housing.push({
      name: this.translate.instant('operation-details.other'),
      oldvalue: operationdata?.gen_other,
      newvalue: changedOperationdata?.gen_other
    });

    if (this.housing.some(value => value.newvalue)) {
      this.changedSections.push('housing');
    }
  }

  private architecturalCompetitionList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.architectural = [];
    this.architectural.push({
      name: this.translate.instant('operation-details.pi-ca-ao-date'),
      oldvalue: this.transformDate(operationdata?.pi_ca_ao_date),
      newvalue: this.transformDate(changedOperationdata?.pi_ca_ao_date),
      oldstatus: this.transformRealDate(operationdata?.pi_ca_ao_date_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.pi_ca_ao_date_reelle)
    });
    this.architectural.push({
      name: this.translate.instant('operation-details.pi-ca-selection-date'),
      oldvalue: this.transformDate(operationdata?.pi_ca_selection_date),
      newvalue: this.transformDate(changedOperationdata?.pi_ca_selection_date),
      oldstatus: this.transformRealDate(operationdata?.pi_ca_selection_date_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.pi_ca_selection_date_reelle)
    });
    this.architectural.push({
      name: this.translate.instant('operation-details.pi-ca-source'),
      oldvalue: operationdata?.pi_ca_source,
      newvalue: changedOperationdata?.pi_ca_source
    });
    this.architectural.push({
      name: this.translate.instant('operation-details.pi-ca-prestataire'),
      oldvalue: operationdata?.pi_ca_prestataire,
      newvalue: changedOperationdata?.pi_ca_prestataire
    });

    if (this.architectural.some(value => value.newvalue)) {
      this.changedSections.push('architectural');
    }
  }

  private subjectMasteryList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.mastery = [];
    this.mastery.push({
      name: this.translate.instant('operation-details.pi-mo-ao-date'),
      oldvalue: this.transformDate(operationdata?.pi_mo_ao_date),
      newvalue: this.transformDate(changedOperationdata?.pi_mo_ao_date),
      oldstatus: this.transformRealDate(operationdata?.pi_mo_ao_date_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.pi_mo_ao_date_reelle)
    });
    this.mastery.push({
      name: this.translate.instant('operation-details.pi-mo-selection-date'),
      oldvalue: this.transformDate(operationdata?.pi_mo_selection_date),
      newvalue: this.transformDate(changedOperationdata?.pi_mo_selection_date),
      oldstatus: this.transformRealDate(operationdata?.pi_mo_selection_date_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.pi_mo_selection_date_reelle)
    });
    this.mastery.push({
      name: this.translate.instant('operation-details.pi-mo-source'), oldvalue: operationdata?.pi_mo_source,
      newvalue: changedOperationdata?.pi_mo_source
    });
    this.mastery.push({
      name: this.translate.instant('operation-details.pi-mo-company'), oldvalue: operationdata?.pi_mo_prestataire,
      newvalue: changedOperationdata?.pi_mo_prestataire
    });

    if (this.mastery.some(value => value.newvalue)) {
      this.changedSections.push('mastery');
    }
  }

  private tendersList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.tenders = [];
    this.tenders.push({
      name: this.translate.instant('operation-details.mi_ao_travaux_date'),
      oldvalue: this.transformDate(operationdata?.mi_ao_travaux_date),
      newvalue: this.transformDate(changedOperationdata?.mi_ao_travaux_date),
      oldstatus: this.transformRealDate(operationdata?.mi_ao_travaux_date_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.mi_ao_travaux_date_reelle)
    });
    this.tenders.push({
      name: this.translate.instant('operation-details.procedure'), oldvalue: operationdata?.procedure,
      newvalue: changedOperationdata?.procedure
    });
    this.tenders.push({
      name: this.translate.instant('operation-details.mi_ao_source'), oldvalue: operationdata?.mi_ao_source,
      newvalue: changedOperationdata?.mi_ao_source
    });
    this.tenders.push({
      name: this.translate.instant('operation-details.mi_ao_deadline_date'),
      oldvalue: this.transformDate(operationdata?.mi_ao_deadline_date),
      newvalue: this.transformDate(changedOperationdata?.mi_ao_deadline_date)
    });

    if (this.tenders.some(value => value.newvalue)) {
      this.changedSections.push('tenders');
    }
  }

  private marketList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.market = [];
    this.market.push({
      name: this.translate.instant('operation-details.mi_resultat_marche_date'),
      oldvalue: this.transformDate(operationdata?.mi_resultat_marche_date),
      newvalue: this.transformDate(changedOperationdata?.mi_resultat_marche_date),
      oldstatus: this.transformRealDate(operationdata?.mi_resultat_marche_date_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.mi_resultat_marche_date_reelle)
    });
    this.market.push({
      name: this.translate.instant('operation-details.mi_resultat_marche_source'),
      oldvalue: operationdata?.mi_resultat_marche_source,
      newvalue: changedOperationdata?.mi_resultat_marche_source
    });
    this.market.push({
      name: this.translate.instant('operation-details.mi_resultat_marche_amount'),
      oldvalue: this.frenchFormat(operationdata?.mi_resultat_marche_montant),
      newvalue: this.frenchFormat(changedOperationdata?.mi_resultat_marche_montant)
    });

    if (this.market.some(value => value.newvalue)) {
      this.changedSections.push('market');
    }
  }

  private transformDate(date): string {
    if (!date)
      return null;
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  private housingList(housing: HousingDetailsModel[], newHousing: HousingDetailsModel[]) {
    housing.forEach(value => {
      value.main_housing_id = '0';
    });
    // store old housing values
    this.housingBuffer = housing.slice();
    // iterate through GetChangedHousing
    newHousing.forEach(newHouse => {
      // IF it is the new housing (main housingId == null)
      if (!newHouse.main_housing_id) {
        // just add it to the Housing array
        housing.push(newHouse);
      } else {
        // find the existing housing in Housing array (mainHousingId == housing.Id)
        const existingHouseIndex = housing.findIndex(h => h.housingId == newHouse.main_housing_id);
        // replace the existing housing with the new one
        housing[existingHouseIndex] = newHouse;
      }
    });
  }

  private fundingList(financial: FinancialDetailsModel[], newFinancial: FinancialDetailsModel[]): void {
    if (!financial || !newFinancial) {
      return;
    }
    financial.forEach(value => {
      value.submission_date = this.transformDate(value?.submission_date);
      value.awarding_date = this.transformDate(value?.awarding_date);
      value.amount = this.frenchFormat(value?.amount);
    });
    newFinancial.forEach(value => {
      value.submission_date = this.transformDate(value?.submission_date);
      value.awarding_date = this.transformDate(value?.awarding_date);
      value.amount = this.frenchFormat(value?.amount);
    });
    this.funding = [];
    this.modifiedFundingValues = [];
    this.funding = financial.map(value => {
      value.main_financial_id = '0';
      return value;
    }).slice();
    financial.forEach(value => {
      const index = newFinancial.findIndex(f => f.main_financial_id == value.id);
      if (index !== -1) {
        this.modifiedFundingValues.push(newFinancial[index]);
        if (index == 0) {
          newFinancial.shift();
        } else {
          newFinancial.splice(index, 1);
        }
      }
    });
    this.funding = this.funding.concat(newFinancial);
  }

  private georeferencingList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.georeferencing = [];
    this.georeferencing.push({
      name: this.translate.instant('operation-details.gps-coordinates'),
      value: operationdata?.gen_geolocation
    });
    this.georeferencing.push({
      name: this.translate.instant('operation-details.sig-source'),
      value: operationdata?.source_sig
    });
  }

  private permitList(operationdata: OperationDataModel, changedOperationdata: any): void {
    this.permit = [];
    this.permit.push({
      name: this.translate.instant('operation-details.building-permission-date'),
      value: this.transformDate(operationdata?.instr_pc_date_depot),
      oldstatus: this.transformRealDate(operationdata?.instr_pc_date_depot_reelle),
      newstatus: this.transformRealDate(changedOperationdata?.instr_pc_date_depot_reelle)
    });
    this.permit.push({
      name: this.translate.instant('operation-details.building-permission-date-attribution'),
      value: this.transformDate(operationdata?.instr_pc_date_attribution)
    });
    this.permit.push({
      name: this.translate.instant('operation-details.building-permission-number'),
      value: operationdata?.instr_pc_numero
    });
  }

  private calculateTableHeigth() {
    let top = document.getElementById("overflow").getBoundingClientRect().top;
    document.getElementById("overflow").style.height = `calc(100vh - ${top + 2 + 'px'})`;
  }

  private buildStepper(data: OperationDataModel): void {
    this.stepper = ['green'];
    if (data.pi_mo_ao_date_reelle) {
      this.stepperPushValue(StepperValues.Green);
    } else if (data.mi_ao_travaux_date_reelle || data.mi_ordre_service_date_reelle || data.mi_livraison_date_reelle) {
      this.stepperPushValue(StepperValues.Red);
    } else {
      this.stepperPushValue(StepperValues.Gray);
    }

    if (data.mi_ao_travaux_date_reelle) {
      this.stepperPushValue(StepperValues.Green);
    } else if (data.mi_ordre_service_date_reelle || data.mi_livraison_date_reelle) {
      this.stepperPushValue(StepperValues.Red);
    } else {
      this.stepperPushValue(StepperValues.Gray);
    }

    if (data.mi_ordre_service_date_reelle) {
      this.stepperPushValue(StepperValues.Green);
    } else if (data.mi_livraison_date_reelle) {
      this.stepperPushValue(StepperValues.Red);
    } else {
      this.stepperPushValue(StepperValues.Gray);
    }

    if (data.mi_livraison_date_reelle) {
      this.stepperPushValue(StepperValues.Green);
    } else {
      this.stepperPushValue(StepperValues.Gray);
    }
  }

  private stepperPushValue(value: StepperValues) {
    if (!constant.IS_SPOT_PROJECT && value === StepperValues.Red) {
      this.stepper.push(StepperValues.Green);
    } else {
      this.stepper.push(value);
    }
  }

  // private getObservations(data: OperationDataModel): void {
  //   this.operationService.getObservations(data.id)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe((resp: any) => {
  //       this.observations = resp.data;
  //     })
  // }

  private getUserId(param) {
    if (param.id) {
      return param.id;
    } else {
      return param;
    }
  }

  private loadWatchOperationUsers() {
    this.watchOperationUsers$ = this.operationService.getWatchOperationUsers(this.operation.operationdata.id).pipe(tap(x => {
      this.watchUsersIds = x?.data?.map(xx => xx.user_id);
    }));
  }

  private transformRealDate(field) {
    return field == 1 ? this.translate.instant('operation-details.real-date') : field == 0 ? this.translate.instant('operation-details.preview-date') : null;
  }

  public validateStep()
  {
    this.ngxLoader.startLoader('op_details_validations');
    this.operationService.validateStep(this.operation.operationdata.id).subscribe((result) => {
      if (result.result) {
        this.operation.validations = result.data
      }
      this.ngxLoader.stopLoader('op_details_validations');
    })
  }

  public getImgUrl(filename) {
    if (filename) {
      return `${constant.ACTIVE_ENVIRONMENT.api_url}/uploads/${filename}`
    } else {
      return "";
    }
  }

  public incomes = [];
  public expenses = [];
  public ie_total: { incomes: string, expenses: string, balance: any } = {
    incomes: "",
    expenses: "",
    balance: {
      sign: "+",
      value: ""
    }
  };

  private initIncomesAndExpenses()
  {
    this.tableService.onDataChange.subscribe(({ table_id, data }) => {
      switch (table_id) {
        case 'income':
          this.incomes = data;
          this.ie_total.incomes = this.tableService.getSum('income', 'amount').toString()
          break;
        case 'expense':
          this.expenses = data;
          this.ie_total.expenses = this.tableService.getSum('expense', 'amount').toString()
          break;
      }

      if (this.ie_total.incomes && this.ie_total.expenses) {
        let balance = Number.parseFloat(this.ie_total.incomes) - Number.parseFloat(this.ie_total.expenses);
        this.ie_total.balance = {
          sign: balance >= 0 ? "+" : "-",
          value: (Math.abs(balance)).toString()
        }
      }
    });

    this.operationService.operationClick$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        this.tableService.setData('income', { operationId: id, filters: this.income_filters });
        this.tableService.setData('expense', { operationId: id, filters: this.income_filters });
      })
  }

  private income_filters = {
    order: {
      order_by: 'id',
      order_way: 'asc'
    },
    search: ""
  };
  private expense_filters = {
    order: {
      order_by: 'id',
      order_way: 'asc'
    },
    search: ""
  };
  public order(table: string, order_by: string)
  {
    let filters = this[table+"_filters"];
    if (filters.order.order_by == order_by) {
      filters.order.order_way = filters.order.order_way == "asc" ? "desc" : "asc";
    } else {
      filters.order.order_by = order_by
      filters.order.order_way = "asc"
    }

    this.tableService.setData(table, { operationId: this.operation.operationdata.id, filters: filters });
  }
}
