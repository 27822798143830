import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddVigilanceComponent} from './components/add-vigilance/add-vigilance.component';
import {EditVigilanceComponent} from './components/edit-vigilance/edit-vigilance.component';
import {VigilancePointRoutingModule} from './vigilance-point-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {VigilanceService} from './services/vigilance.service';
import { VigilanceListComponent } from './components/vigilances-list/vigilance-list.component';


@NgModule({
  declarations: [AddVigilanceComponent, EditVigilanceComponent, VigilanceListComponent],
  imports: [
    CommonModule,
    VigilancePointRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [VigilanceService],
  exports: [AddVigilanceComponent, EditVigilanceComponent]
})
export class VigilancePointModule {
}
