import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ObservationModel} from '../../../operation/model/observation-model';
import {ObservationService} from '../../services/observation.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {DialogBase} from "../../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-add-observation',
  templateUrl: './add-observation.component.html',
  styleUrls: ['./add-observation.component.scss']
})
export class AddObservationComponent extends DialogBase implements OnInit, OnDestroy {
  public observation: ObservationModel = {};
  private id: number;

  constructor(public observationService: ObservationService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.loadData(data);
  }

  ngOnInit(): void {
  }

  public loadData(data): void {
    this.id = data.id;
  }

  public cancel(): void {
    this.observationService.closeObservation$.next();
  }

  public addObservation(): void {
    this.isCalled = true;
    this.observation.id = this.id.toString();
    this.observationService.addObservation(this.observation)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value.result == 'true') {
          this.observationService.addObservationList$.next(this.observation);
        }
        this.cancel();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
