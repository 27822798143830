<div class="flex">
  <div class="left">
    <div>
      <img class="logo" src="{{cdn_url}}/assets/icons/{{logo_url}}" alt="">
    </div>
    <img class="main-picture" src="{{cdn_url}}/assets/images/login-main-picture.png" alt="">
    <h3>Système Permanent d’Observation Territoriale (SPOT) </h3>
    <span>@ Système d'information collaboratif réalisé par Yukan Innovate</span>
  </div>
  <div class="right">
    <h2>{{ 'registration.title' | translate }}</h2>
    <form [formGroup]="form">
      <div class="flex-2">
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('firstname')}" >{{ 'registration.firstname' | translate }}</span>
          <input matInput type="text" formControlName="firstname" [(ngModel)]="user.name" [ngClass]="{'dirty-form': isControlInvalid('firstname')}"
                 placeholder="{{ 'registration.firstname-help' | translate }}">
        </div>
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('lastname')}" >{{ 'registration.lastname' | translate }}</span>
          <input matInput type="text" formControlName="lastname" [(ngModel)]="user.surname" [ngClass]="{'dirty-form': isControlInvalid('lastname')}"
                 placeholder="{{ 'registration.lastname-help' | translate }}">
        </div>
      </div>
      <div class="flex-2">
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('email')}">{{ 'registration.email' | translate }}</span>
          <input matInput type="email" formControlName="email" [(ngModel)]="user.email" [ngClass]="{'dirty-form': isControlInvalid('email')}"
                 placeholder="{{ 'registration.email-help' | translate }}">
        </div>
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('phone')}">{{ 'registration.phone' | translate }}</span>
          <input matInput type="number" formControlName="phone" [(ngModel)]="user.phone" [ngClass]="{'dirty-form': isControlInvalid('phone')}"
                 placeholder="{{ 'registration.phone-help' | translate }}">
        </div>
      </div>
      <div class="flex-2">
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('password')}">{{ 'registration.password' | translate }}</span>
          <input matInput type="password" formControlName="password" [(ngModel)]="user.password" [ngClass]="{'dirty-form': isControlInvalid('password')}"
                 placeholder="{{ 'registration.password-help' | translate }}">
        </div>
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('confirmPassword')}">{{ 'registration.confirm-password' | translate }}</span>
          <input matInput type="password" formControlName="confirmPassword"  [ngClass]="{'dirty-form': isControlInvalid('confirmPassword')}"
                 placeholder="{{ 'registration.confirm-password-help' | translate }}">
        </div>
      </div>
      <div class="flex-2">
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('type_organisation')}">{{ 'registration.org-type' | translate }}</span>
          <input matInput type="text" formControlName="type_organisation" [(ngModel)]="user.type_of_organisation" [ngClass]="{'dirty-form': isControlInvalid('type_organisation')}"
                 placeholder="{{ 'registration.org-type-help' | translate }}">
        </div>
        <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('name_organisation')}">{{ 'registration.org-name' | translate }}</span>
          <input matInput type="text" formControlName="name_organisation" [(ngModel)]="user.name_of_organisation" [ngClass]="{'dirty-form': isControlInvalid('name_organisation')}"
                 placeholder="{{ 'registration.org-name-help' | translate }}">
        </div>
      </div>
      <div class="">
        <span [ngClass]="{'dirty-label': isControlInvalid('function')}">{{ 'registration.function' | translate }}</span>
        <input matInput type="text" formControlName="function" [(ngModel)]="user.function" [ngClass]="{'dirty-form': isControlInvalid('function')}"
               placeholder="{{ 'registration.function-help' | translate }}">
      </div>

      <div class="">

        <span [ngClass]="{'dirty-label': isControlInvalid('object_request')}">{{ 'registration.subject' | translate }}</span>
        <textarea matInput formControlName="object_request" [(ngModel)]="user.object_request" [ngClass]="{'dirty-form': isControlInvalid('object_request')}"
                  placeholder="{{ 'registration.subject-help' | translate }}" rows="3">
          </textarea>

      </div>
    </form>
    <div class="buttons">
      <button class="login" (click)="redirect('/login')">{{ 'registration.login' | translate }}</button>
      <button class="register" (click)="register()">{{ 'registration.register' | translate }}</button>
    </div>
  </div>
</div>
